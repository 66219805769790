<template>
  <b-card no-body class="my-2">
    <b-card-header>
      Movimientos

      <div class="w-100 d-flex justify-content-end align-items-center">
        <!-- Nuevo registro. Abre sidebar. -->
        <b-button
          class="mr-3 mb-2 mb-sm-0"
          size="sm"
          variant="primary"
          :disabled="isLoading"
          v-if="isEditable"
          v-b-toggle="'addMovimientoTraspaso_' + traspaso_id"
          @click="addMovimiento"
        >
          Agregar
        </b-button>

        <div class="dropdown no-caret">
          <button
            class="btn btn-transparent-dark btn-icon dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            <i data-feather="more-vertical"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
            <a class="dropdown-item my-cursor">
              Recargar
            </a>
          </div>
        </div>
      </div>
    </b-card-header>

    <b-card-body class="p-1">
      <b-overlay :show="isLoading">
        <template #overlay>
          <loading />
        </template>
      <b-table
        :items="movimientos"
        :fields="fields"
        show-empty
        responsive
        sticky-header
        :busy="isLoading"
        style="height: 300px"
      >
        <template #cell(fuente_financiamiento)="data">
          <div v-if="data.item.importe >= 0" class="text-primary text-center text-sm w-100">
            {{ data.item.fuente_financiamiento }}
          </div>
          <div v-else class="text-danger text-center text-sm w-100">
            {{ data.item.fuente_financiamiento }}
          </div>
        </template>
        <template #cell(proyecto)="data">
          <div v-if="data.item.importe >= 0" class="text-primary text-center text-sm w-100">
            {{ data.item.proyecto }}
          </div>
          <div v-else class="text-danger text-center text-sm w-100">
            {{ data.item.proyecto }}
          </div>
        </template>
        <template #cell(unidad_administrativa)="data">
          <div v-if="data.item.importe >= 0" class="text-primary text-center text-sm w-100">
            {{ data.item.unidad_administrativa }}
          </div>
          <div v-else class="text-danger text-center text-sm w-100">
            {{ data.item.unidad_administrativa }}
          </div>
        </template>
        <template #cell(partida)="data">
          <div v-if="data.item.importe >= 0" class="text-primary text-center text-sm w-100">
            {{ data.item.partida }}
          </div>
          <div v-else class="text-danger text-center text-sm w-100">
            {{ data.item.partida }}
          </div>
        </template>
        <template #cell(ampliacion)="data">
          <div v-if="data.item.importe >= 0" class="text-primary text-center text-sm w-100">
            {{ $formatNumber(data.item.importe) }}
          </div>
        </template>
        <template #cell(reduccion)="data">
          <div v-if="data.item.importe < 0" class="text-danger text-center text-sm w-100">
            {{ $formatNumber(-data.item.importe) }}
          </div>
        </template>
        <template #cell(acciones)="row">
          <div v-if="isEditable" class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
            <u-d-controls
              editPath="suficienciaPresupuestalModule/getMovimientoById"
              deletePath="suficienciaPresupuestalModule/deleteMovimientoById"
              :id="row.item.id"
              :editPermissions="['modificar suficiencia presupuestal']"
              :deletePermissions="['eliminar suficiencia presupuestal']"
              double-confirmation
              :doubleConfirmationParams="{ confirmation: true }"
              @on-deleted="onDeleteItem"
            >
              <template #edit>
                <b-button
                  class="btn-sm btn-outline-blue mr-3 mb-2 mb-sm-0"
                  variant="white"
                  v-b-toggle="'addMovimientoTraspaso_' + traspaso_id"
                  @click="onEditItem(row.item.id)"
                >
                  Editar
                </b-button>
              </template>
            </u-d-controls>
          </div>
        </template>
      </b-table>
      </b-overlay>
    </b-card-body>

    <!-- Sidebar para agregar de manera manual los registros de suficiencia -->
    <b-sidebar
      :id="`addMovimientoTraspaso_${traspaso_id}`"
      right
      width="600px"
      shadow
      backdrop
      no-header
      no-close-on-backdrop
      no-close-on-esc
      aria-labelledby="sidebar-no-header-title"
    >
      <template #default="{ hide }">
        <!-- Form para importar layout de nómina -->
        <div class="p-3">
          <h4 id="sidebar-no-header-title">Seleccione los clasificadores y determine el importe a verificar</h4>
          <traspasos-movimientos-form
            :traspaso_id="traspaso_id"
            :movimiento_id="movimiento_id"
            :is-editing="isEditing"
            @on-result="hide()
            refresh()"
          >
            <template #btn-cancel-place="{clearForm}">
              <b-button
                variant="dark"
                size="sm"
                @click="
                  clearForm()
                  hide()
                  refresh()
                "
              >
                Cerrar
              </b-button>
            </template>
          </traspasos-movimientos-form>
        </div>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import TraspasosMovimientosForm from './TraspasosMovimientosForm'

export default {
  name: 'TraspasosMovimientosList',

  components: {
    TraspasosMovimientosForm
  },

  props: {
    traspaso_id: {
      required: true
    },

    isEditable: {
      default: false
    }
  },

  async mounted () {
    this.isLoading = true
    this.movimientos = await this.getMovimientosTraspasoById(this.traspaso_id)
    this.isLoading = false
  },

  data () {
    return {
      movimientos: null,

      movimiento_id: null,

      isEditing: false,

      isLoading: false,

      fields: ['fuente_financiamiento', 'proyecto', 'unidad_administrativa', 'partida', 'ampliacion', 'reduccion', 'acciones']
    }
  },

  methods: {
    ...mapActions('traspasoModule', ['getMovimientosTraspasoById']),

    addMovimiento () {
      this.isEditing = false
      this.movimiento_id = null
    },

    onEditItem (id) {
      this.isEditing = true
      this.movimiento_id = id
      //
      // Manda un flag para indicar que debe esperar que el item cargue.
      this.$store.commit('traspasoModule/setWaitToEditItem', true)

      //
      // Obtiene el item desde un dispatch
      this.$store.dispatch('traspasoModule/getMovimientoById', id)
    },

    onDeleteItem (data) {
      this.$alert(data, 'Traspaso.', 'notificationSuficienciaAlertPlace', {
        status: data.error ? 'danger' : 'success'
      })
      this.refresh()

      // this.loadList()
    },

    ...mapMutations('traspasoModule', ['setImporteTraspaso']),
    async refresh () {
      // Obtenemos movimientos
      this.isLoading = true
      this.movimientos = await this.getMovimientosTraspasoById(this.traspaso_id)
      // Actualizamos registros de suficiencia presupuestal (la suma)
      const sum = this.movimientos.map(m => parseFloat(m.importe)).reduce(
        (previousValue, currentValue) => previousValue + currentValue, 0.00
      )
      const ampliaciones = this.movimientos.map(m => parseFloat(m.importe)).filter(m => m >= 0).reduce(
        (previousValue, currentValue) => previousValue + currentValue, 0.00
      )
      const reducciones = this.movimientos.map(m => parseFloat(m.importe)).filter(m => m < 0).reduce(
        (previousValue, currentValue) => previousValue + currentValue, 0.00
      )
      const payload = {
        id: this.traspaso_id,
        importe: sum,
        ampliaciones: ampliaciones,
        reducciones: -reducciones
      }
      this.setImporteTraspaso(payload)
      this.isLoading = false
    }
  }
}
</script>
