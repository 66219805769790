<template>
  <div>
    <loading v-if="loading" :message="isEditing ? 'Actualizando movimiento de traspaso...' : 'Agregando movimiento de traspaso...'"></loading>
    <b-form v-else @submit.prevent="submit">
      {{ traspaso_id }}
      <b-alert :show="processed" :variant="variant">
        {{ msg }}
      </b-alert>

      <!-- Fuente de Financiamiento -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Fuente de Financiamiento
            <span class="text-danger">*</span>
          </label>
        </template>
        <v-select
          style="background: #ffffff"
          id="suficiencia-input-fuente_financiamiento_id"
          class="style-chooser"
          v-model="fuente_financiamiento_id"
          :options="fuentes_financiamiento_list"
          :reduce="item => item.value"
          required
        />
      </b-form-group>

      <!-- Proyecto -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Proyecto
            <span class="text-danger">*</span>
          </label>
        </template>
        <v-select
          id="suficiencia-input-proyecto_id"
          style="background: #ffffff"
          v-model="proyecto_id"
          :options="proyectos_list"
          :reduce="item => item.value"
          required
        />
      </b-form-group>

      <!-- Unidad Administrativa -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Unidad Administrativa
            <span class="text-danger">*</span>
          </label>
        </template>
        <v-select
          id="suficiencia-input-unidad_administrativa_id"
          v-model="unidad_administrativa_id"
          :options="unidades_administrativas_list"
          :reduce="item => item.value"
          style="background: #ffffff"
          required
        />
      </b-form-group>

      <!-- Tipo de Gasto -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Tipo de Gasto
            <span class="text-danger">*</span>
          </label>
        </template>
        <v-select
          id="suficiencia-input-tipo_gasto_id"
          v-model="tipo_gasto_id"
          :options="tipos_gasto_list"
          :reduce="item => item.value"
          style="background: #ffffff"
          required
        />
      </b-form-group>

      <!-- Partida -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Partida
            <span class="text-danger">*</span>
          </label>
        </template>
        <v-select
          id="suficiencia-input-partida_id"
          v-model="partida_id"
          :options="partidas_list"
          :reduce="item => item.value"
          style="background: #ffffff"
          required
        >
          <template v-slot:spinner>
            <div v-show="loadingPartidas">
              <div class="d-flex align-items-center">
                <strong>Cargando...</strong>
                <b-spinner variant="info" small class="ml-auto" />
              </div>
            </div>
          </template>
        </v-select>
      </b-form-group>

      <!-- Importe -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Importe
            <span class="text-danger">*</span>
          </label>
        </template>
        <b-form-input v-model="payload.importe"></b-form-input>
      </b-form-group>
      <!-- Submit -->
      <div class="d-flex justify-content-between">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>
        <b-button type="submit" size="sm" variant="success" :disabled="!isFormVal">
          {{ 'Guardar' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>

const payload = () => ({
  traspaso_id: null,
  fuente_financiamiento_id: null,
  unidad_administrativa_id: null,
  proyecto_id: null,
  partida_id: null,
  tipo_gasto_id: null,
  importe: null
})

export default {
  name: 'SuficienciaMovimientosForm',

  props: {
    traspaso_id: {
      required: true
    },

    movimiento_id: {
      required: true
    },

    isEditing: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      payload: payload(),

      // Vamos a usar estas propiedades para gestionar la carga de listados y posteriormente se asignarán al payload
      fuente_financiamiento_id: null,
      unidad_administrativa_id: null,
      proyecto_id: null,
      partida_id: null,
      tipo_gasto_id: null,

      periodo_fiscal_id: null,

      fuentes_financiamiento_list: [],

      unidades_administrativas_list: [],

      proyectos_list: [],

      partidas_list: [],

      tipos_gasto_list: [],

      variant: 'info',
      processed: false,
      msg: '',

      loading: false,

      loadingPartidas: false
    }
  },

  computed: {
    isFormVal () {
      return [
        Boolean(this.fuente_financiamiento_id),
        Boolean(this.unidad_administrativa_id),
        Boolean(this.proyecto_id),
        Boolean(this.partida_id),
        Boolean(this.tipo_gasto_id),
        Boolean(this.payload.importe)
      ].every(e => e)
    }
  },

  watch: {
    movimiento_id: {
      immediate: true,

      async handler (value) {
        if (value) {
          this.loading = true
          this.payload = await this.$store.dispatch('traspasoModule/getMovimientoTraspasoById', value)
          this.loading = false
        } else {
          this.payload = payload()
        }
      }
    },

    fuente_financiamiento_id: {
      immediate: true,
      async handler (value) {
        if (value) {
          this.partida_id = null
          this.loadPartidas()
        }
      }
    },

    proyecto_id: {
      immediate: true,
      async handler (value) {
        if (value) {
          this.partida_id = null
          this.loadPartidas()
        }
      }
    },

    unidad_administrativa_id: {
      immediate: true,
      async handler (value) {
        if (value) {
          this.partida_id = null
          this.loadPartidas()
        }
      }
    },

    tipo_gasto_id: {
      immediate: true,
      async handler (value) {
        if (value) {
          this.partida_id = null
          this.loadPartidas()
        }
      }
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      //
      // Carga el periodo fiscal
      this.periodo_fiscal_id = this.$store.getters[
        'fiscalPeriodsModule/getActiveFiscalPeriod'].id

      //
      // Inicializa mensajes
      this.showMessage()

      //
      // Carga listadoss
      this.load()
    },

    async load () {
      this.loading = true

      // Obtenemos fuentes de financiamiento
      const fuenteslist = await this.$store.dispatch('fuenteFinanciamientoModule/getFuentesFinanciamientoList',
        this.periodo_fiscal_id)
      this.fuentes_financiamiento_list = fuenteslist.map(f => ({ value: f.id, label: f.clave + ' ' + f.nombre }))

      // Obtenemos unidades administrativas
      const unidadeslist = await this.$store.dispatch('administrativeUnitModule/getUnidadesAdministrativasList',
        this.periodo_fiscal_id)
      this.unidades_administrativas_list = unidadeslist.map(f => ({ value: f.id, label: f.clave + ' ' + f.nombre }))

      // Obtenemos proyectos
      const proyectoslist = await this.$store.dispatch('proyectoModule/getProyectosList',
        this.periodo_fiscal_id)
      this.proyectos_list = proyectoslist.map(f => ({ value: f.id, label: f.clave + ' ' + f.nombre }))

      // Obtenemos tipos de gasto
      const tiposGastoList = await this.$store.dispatch('tipoGastoModule/getTiposGastoList',
        this.periodo_fiscal_id)
      this.tipos_gasto_list = tiposGastoList.map(f => ({ value: f.id, label: f.clave + ' ' + f.nombre }))

      this.loading = false
    },

    async loadPartidas () {
      // Obtenemos partidas con disponibilidad presupuestal
      this.partidas_list = []
      if (this.fuente_financiamiento_id && this.proyecto_id && this.unidad_administrativa_id && this.tipo_gasto_id) {
        this.loadingPartidas = true
        const partidaslist = await this.$store.dispatch('partidaModule/getPartidasPresupuestoList',
          {
            periodo_fiscal_id: this.periodo_fiscal_id,
            fuente_financiamiento_id: this.fuente_financiamiento_id,
            proyecto_id: this.proyecto_id,
            unidad_administrativa_id: this.unidad_administrativa_id,
            tipo_gasto_id: this.tipo_gasto_id
          })
        this.partidas_list = partidaslist.map(f => ({ value: f.id, label: f.clave + ' ' + f.nombre + ' ' + f.disponible }))
        this.loadingPartidas = false
      }
    },

    async submit () {
      this.loading = true
      this.payload.traspaso_id = this.traspaso_id
      this.payload.fuente_financiamiento_id = this.fuente_financiamiento_id
      this.payload.proyecto_id = this.proyecto_id
      this.payload.unidad_administrativa_id = this.unidad_administrativa_id
      this.payload.tipo_gasto_id = this.tipo_gasto_id
      this.payload.partida_id = this.partida_id
      const getval = this.isEditing
        ? await this.update(this.payload.id, this.payload)
        : await this.create(this.payload)
      // Volvemos a cargar los movimientos
      await this.refresh(this.traspaso_id)
      this.loading = false

      //
      // Muestra una alerta si ha ocurrido un error
      this.processed = true
      this.msg = getval.message
      this.variant = getval.error ? 'danger' : 'success'

      if (getval.error) return

      this.showMessage(getval.message)
      this.emitResult(getval)
      this.clearForm()
    },

    async create (payload) {
      return await this.$store.dispatch('traspasoModule/crearMovimiento', payload)
    },

    async update (id, payload) {
      return await this.$store.dispatch('traspasoModule/updateMovimientoById', { id, payload })
    },

    async refresh (id) {
      return await this.$store.dispatch('traspasoModule/getTraspasoById', id)
    },

    showMessage (msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: 'Correcto',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },

    clearForm () {
      this.payload = payload()
      this.fuente_financiamiento_id = null
      this.proyecto_id = null
      this.unidad_administrativa_id = null
      this.tipo_gasto_id = null
      this.partida_id = null

      this.processed = false
      this.variant = 'info'
      this.msg = ''
    },

    emitResult (data) {
      this.$emit('on-result', data)
    }
  }
}
</script>
